export default function Header(el, {
	sentinalHandle,
	mobileCloseHandle,
	openClass,
	fixedClass,
}) {
	const sentinal = el.querySelector(sentinalHandle);
	const mobileClose = el.querySelector(mobileCloseHandle);
	const mobileMenuTrigger = el.querySelector('nav > button');
	const navMenuTriggers = el.querySelectorAll('nav li button');

	const handleFix = ([{ isIntersecting }]) => {
		el.classList.toggle(fixedClass, !isIntersecting); };
	const fixObserver = new IntersectionObserver(handleFix);
	fixObserver.observe(sentinal);

	mobileClose.onclick = () => {
		el.classList.remove(openClass); };
	mobileMenuTrigger.onclick = () => {;
		el.classList.toggle(openClass); };
	navMenuTriggers.forEach(t => t.onclick = ({ currentTarget }) => {
		currentTarget.classList.toggle(openClass); });
}
