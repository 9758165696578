// Components
import pop from './components';
import DownloadPopup from './components/download-popup';
import Form from './components/form';
import FormField from './components/form-field';
import Header from './components/header';
import ResourcesListing from './components/resources-listing';

/* eslint-disable quote-props */
const classMap = {
	'download-popup': DownloadPopup,
	'form': Form,
	'form-field': FormField,
	'header': Header,
	'resources-listing': ResourcesListing,
};
/* eslint-enable quote-props */

const actions = {
	showHeader: 'show-header',
	hideHeader: 'hide-header',
	openModal: 'open-modal',
	closeModal: 'close-modal',
	loadModal: 'load-modal',
	showFieldError: 'show-field-error',
	showPopup: 'show-popup',
	hidePopup: 'hide-popup',
	formSubmitSuccess: 'form-submit-success',
};

// Event handler functions
function handleDOMConentLoaded() {
	function cb() {
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');
	}

	// Call component constructors
	pop({ classMap, actions, cb });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
