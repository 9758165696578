export default function ScheduleForm(el, {
	name,
	actions,
	events,
}) {
	const error = el.querySelector('label ~ p');

	events.on(actions.showFieldError, ({ detail }) => {
		if (detail.name === name) {
			error.textContent = detail.errors.join(', ');
		}});
}
