export default function ResourcesListing(el, {
	listHandle,
	moreHandle,
}) {
	const list = el.querySelector(listHandle);
	const more = el.querySelector(moreHandle);

	let page = 1;

	more.onclick = e => {
		e.preventDefault();

		more.setAttribute('disabled', true);
		page++;

		fetch(`/shared/resources-listing/p${page}`)
			.then(response => response.json())
			.then(({ resources, hasMore }) => {
				list.insertAdjacentHTML('beforeend', resources)
				more.removeAttribute('disabled');
				more.style.display = hasMore ? 'flex' : 'none';
			});
	}
}
