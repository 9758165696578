export default function DownloadPopup(el, {
	activeClass = 'is-active',
	actions,
	events,
}) {
	const form = el.querySelector('form');
	const close = el.querySelector('header ~ button');

	const handleKeyup = ({ key }) => {
		if (key === 'Escape') {
			events.emit(actions.hidePopup);
		}
	}
	const handleShowPopup = () => {
		el.classList.add(activeClass);
		document.addEventListener('keyup', handleKeyup);
	};
	const handleHidePopup = () => {
		fetch('/actions/forms-module/forms/hide-popup', {
			headers: { 'Accept': 'application/json' },
		})
			.then(res =>  res.json().then(json => ({
				status: res.status,
				...json,
			})))
			.then(({
				hidden,
			})=> {
				el.classList.remove(activeClass);
				document.removeEventListener('keyup', handleKeyup);
			});
	};

	events.on(actions.showPopup, handleShowPopup);
	events.on(actions.hidePopup, handleHidePopup);
	events.on(actions.formSubmitSuccess, handleHidePopup);
	close.onclick = handleHidePopup;

	setTimeout(handleShowPopup, 15000);
}
